import React, { useEffect, useState } from "react";
import {
    Box
} from '@mui/material';
const AboutUs = (props) => {
    return (
        <Box className="aboutus">
            <Box sx={{color: "#f4364f"}}><h2>Hi! Welcome to Travel India</h2></Box>
            <Box className="aboutus-content">We are Travel India group. We organise trips in Jammu & Kashmir, Himachal Pradesh, Uttarakhand and Sikkim. Our qualified and experienced team of local guides, trek/expedition leaders and cooks are known for their dedicated work in the mountains.
We try to provide great experience with in the budget friendly cost. Our motto is not to burden the pockets when we travel and enjoy to the fullest.
            </Box>
        </Box>
    )
};
export default AboutUs;