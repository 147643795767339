import React, { useEffect, useState } from "react";
import {
    Box, Grid
} from '@mui/material';
import list from './Package.json';
import Card from "./Card";
const Packages = (props) => {
    const showPackageDetails = (row) => {
        props.clearSelectedTab();
        let obj = {
            show: true,
            pkgid: row.pkgid,
            path: row.pkgid + "#" + row.title.split(' ').join('-')
        };
        props.clearSelectedTab(obj);
    };
    const filterCondition = (item) => {
        return item.location.toLowerCase().indexOf(props.filterValue.toLowerCase()) > -1 || 
        item.title.toLowerCase().indexOf(props.filterValue.toLowerCase()) > -1;
    }
    return (
        <Box>
            {
                props.filterValue ?
                    <Box justifyContent="center" alignItems="center" display="flex" sx={{ mb: "10px", ml: "20px", width: "97%" }}>
                        <h2>Search Results for:</h2> <Box className="ml10 fs20">{props.filterValue}</Box> 
                    </Box> :
                    <Box justifyContent="left" display="flex" sx={{ mb: "10px", ml: "20px" }}>
                        <strong>Available Packages</strong>
                    </Box>
            }

            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" sx={{ padding: "5px" }}>
            <Box sx={{width: '97%'}}>
                <Grid container spacing={2}>
                    {
                        list && list.packages && list.packages.filter(l => filterCondition(l)).map((row, index) => {
                            return (
                                <Grid item md={4} sm={4} xs={12}>
                                    <Card key={row.pkgid}
                                        data={row}
                                        showPackageDetails={() => showPackageDetails(row)} />
                                </Grid>
                            )
                        })
                    }

                </Grid>
            </Box>
            </Box>
        </Box>
    )
};
export default Packages;