import React, { useEffect, useState } from "react";
import {
    Box
} from '@mui/material';
const Card = (props) => {
    const { data } = props;
    const getFormattedPrice = (amount, unit) => {
        let curr = "";
        if (unit == "INR") {
            curr = "₹";
        }
        if (amount) return curr + " " + thousands_separators(amount);
    };
    const thousands_separators = (num) => {
        let num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num_parts.join(".");
    };
    return (
        <Box className="card">
            <Box style={{display: "flex", alignContent:"center"}}>
                <img src={require('./images/' + data.cardImage)} width="100%" height="180"
                    className="clickable card-img"
                    onClick={() => props.showPackageDetails()}
                />
            </Box>            
            <Box className="card-title">{data.title}: {data.duration}</Box>
            <Box style={{display: "flex", justifyContent:"space-between"}} className="card-footer">
                <Box>{getFormattedPrice(data.price, data.unit)}</Box>
                <Box className="clickable card-button"  onClick={() => props.showPackageDetails()}>Book Now</Box>
            </Box>
        </Box>
    )
};
export default Card;