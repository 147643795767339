import React, { useEffect, useState } from "react";
import {
    Tooltip, Box, IconButton
} from '@mui/material';
import {
    useParams
} from "react-router-dom";
import whatsappicon from "./images/whatsapp1.png";
import whatsappicon1 from "./images/whatsappicon2.jpg";
import list from './Package.json';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CallIcon from '@mui/icons-material/Call';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Detail.css';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AvailabileDates from "./AvailableDates";
const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
const Detail = (props) => {
    const [pack, setPack] = useState({});
    let { pkgid } = useParams();
    const getFormattedPrice = (amount, unit) => {
        let curr = "";
        if (unit == "INR") {
            curr = "₹";
            if (amount) return curr + " " + thousands_separators(amount);
        }
        if (amount) return thousands_separators(amount);
    };
    const thousands_separators = (num) => {
        let num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num_parts.join(".");
    };
    useEffect(() => {
        if (list && list.packages) {
            let obj = list.packages.find(p => p.pkgid == pkgid);
            setPack({ ...obj });
        }
    }, [props.pkgid]);
    const toggleFAQ = (index, value) => {
        pack.faq[index].expanded = value;
        setPack({...pack});
    }
    return (
        <Box className="package-detail">
            <Box display="flex" justifyContent="left"
                className="package-detail-title">
                {pack.title} : {pack.duration}
            </Box>
            <Box display="flex" justifyContent="left" alignItems="baseline" sx={{ fontWeight: "600" }}>
                <Box className="package-detail-amount fs16 ml5">₹</Box>
                <Box className="package-detail-amount fs25 ml5">{getFormattedPrice(pack.price)}</Box>
                <Box className="fs25 ml5">/</Box>
                <Box className="fs16">Per Person</Box>
            </Box>
            <Box display="flex" justifyContent="left" alignItems="center" className="package-detail-location">
                <LocationOnIcon color="error" fontSize="small" />
                {pack.location}
            </Box>
            {
                pack.carousel && (
                    <Box sx={{ mt: "10px" }}>
                        <Carousel responsive={responsive} infinite={true}>
                            {pack.carousel.split(",").map(c => (
                                <div>
                                    <img src={require('./images/' + c)} width="98%" height="240" />
                                </div>
                            ))}
                        </Carousel>
                    </Box>
                )
            }                            
            <Box display="flex">
                <Box className="package-detail-body">
                    <Box>
                        <Box className="package-detail-body-title">About Package</Box>
                        <Box className="fs16" dangerouslySetInnerHTML={{ __html: pack.detail }} />
                    </Box>                    
                    <Box className="package-detail-summary">
                        <Box className="package-detail-summary-title">Package Summary</Box>
                        {
                            pack.itinerary && pack.itinerary.map(it => (
                                <Box display="flex" justifyContent="left" alignItems="center"
                                className="package-detail-summary-item">
                                    <Box className="package-detail-summary-item-seq">{it.title}</Box>
                                    <Box className="package-detail-summary-item-title">{it.descr}</Box>
                                </Box>
                            ))

                        }
                    </Box>
                    <Box>
                        {
                            pack.map && (
                                <img src={require('./images/' + pack.map)} width="100%" height="500" />
                            )
                        }
                    </Box>
                    <Box>
                        <Box display="flex" justifyContent="left" alignItems="center" className="package-detail-itinerary">
                            <Box>Detailed Day Wise</Box>
                            <Box sx={{ ml: "5px", color: "#f4364f" }}>Itinerary</Box>
                        </Box>
                        <ul className="timeline">
                            {
                                pack.itinerary && pack.itinerary.map(it => (
                                    <li>
                                        <Box display="flex" justifyContent="left" alignItems="center" sx={{ mb: "10px", mt: "20px" }}>
                                            <Box className="package-detail-itinerary-seq">{it.title} :</Box>
                                            <Box className="package-detail-itinerary-title">{it.descr}</Box>
                                        </Box>
                                        <Box dangerouslySetInnerHTML={{ __html: it.detail }} />
                                        
                                        <Box className="package-detail-itinerary-footer">
                                            {!pack.hideEndOfDay && (
                                                <Box className="package-detail-itinerary-footer-text" >End of Day</Box>
                                            )}
                                        </Box>
                                       
                                    </li>
                                ))
                            }
                        </ul>
                    </Box>
                    <Box>
                        <Box display="flex" justifyContent="left" alignItems="center" className="package-detail-condition">
                            <Box>Trip Information & Conditions</Box>
                        </Box>
                        <Box>
                            {
                                pack.conditions && pack.conditions.map(cnd => (
                                    <Box>
                                        <Box sx={{ fontSize: "17px", fontWeight: "600" }}><span style={{ color: "#f4364f" }}>*</span> {cnd.title}</Box>
                                        <ul style={{ marginTop: "8px" }}>
                                            {
                                                cnd.items && cnd.items.map(it => (
                                                    <li key={it.key}>{it.value}</li>
                                                ))
                                            }
                                        </ul>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                    {
                        pack.essentials && (
                            <Box>
                                <Box display="flex" justifyContent="left" alignItems="center" className="package-detail-condition">
                                    <Box>Things to Carry</Box>
                                </Box>
                                <Box>
                                    {
                                        pack.essentials.header && (
                                            <Box className="fs16" dangerouslySetInnerHTML={{ __html: pack.essentials.header }} />
                                        )
                                    }
                                </Box>
                                <Box sx={{mt:"10px"}}>
                                {
                                    pack.essentials.items && (
                                        <Box>
                                            {
                                                pack.essentials.items.map((it, index) => (
                                                    <Box>
                                                        <Box className="fw600 fs18">
                                                            {index + 1}. {it.key}
                                                        </Box>
                                                        <Box className="fs16" dangerouslySetInnerHTML={{ __html: it.value }} />
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                    )
                                }    
                                </Box>                            
                            </Box>
                        )
                    }            
                    {
                        pack.reachStartPoint && (
                            <Box>
                                <Box display="flex" justifyContent="left" alignItems="center" className="package-detail-condition">
                                    <Box>{pack.reachStartPoint.title}</Box>
                                </Box>
                                <Box sx={{ mt: "20px" }}>
                                    {
                                        pack.reachStartPoint.items && (
                                            <Box>
                                                {
                                                    pack.reachStartPoint.items.map((it, index) => (
                                                        <Box sx={{mb:"20px"}}>
                                                            <Box className="fw600 fs18">
                                                                {it.key}
                                                            </Box>
                                                            <Box className="fs16 mt5" dangerouslySetInnerHTML={{ __html: it.value }} />
                                                        </Box>
                                                    ))
                                                }
                                            </Box>
                                        )
                                    }
                                    {
                                        pack.reachStartPoint.footer && (
                                            <Box className="fs16 mt5" dangerouslySetInnerHTML={{ __html: pack.reachStartPoint.footer }} />
                                        )
                                    }
                                </Box>
                            </Box>
                        )
                    }    
                    {
                        pack.faq && (
                            <Box>
                                <Box display="flex" justifyContent="left" alignItems="center" className="package-detail-condition">
                                    <Box>FAQs for {pack.title}</Box>
                                </Box>
                                <Box>
                                    {
                                        pack.faq.map((it, index) => (
                                            <Box sx={{ mb: "20px" }}>
                                                <Box className="fw600 fs16" display="flex" alignItems="center">
                                                    {it.expanded ?
                                                        <IconButton onClick={e => toggleFAQ(index, false)} >
                                                            <ArrowDropDownIcon />
                                                        </IconButton> :
                                                        <IconButton onClick={e => toggleFAQ(index, true)} >
                                                            <ArrowRightIcon />
                                                        </IconButton>
                                                    }
                                                    <Box className="clickable" onClick={e => toggleFAQ(index, !it.expanded)}>{it.key}</Box>
                                                </Box>
                                                {it.expanded && (
                                                    <Box className="fs16 mt5 ml20" dangerouslySetInnerHTML={{ __html: it.value }} />
                                                )}                                               
                                            </Box>
                                        ))
                                    }
                                </Box>
                            </Box>
                        )
                    }    
                </Box>
                <Box className="package-detail-rightPanel">
                    {
                        pack.dates && (
                            <Box className="package-detail-rightPanel-box package-detail-rightPanel-dates">
                                <AvailabileDates dates={pack.dates} />
                            </Box>
                        )
                    }                    
                    <Box className="package-detail-rightPanel-box package-detail-rightPanel-contact">
                        <Box sx={{ backgroundColor: "#f2f2f2", padding: "1rem" }}>
                            <Box display="flex" justifyContent="left" alignItems="baseline" sx={{ fontWeight: "600" }}>
                                <Box className="package-detail-amount fs16 ml5">₹</Box>
                                <Box className="package-detail-amount fs25 ml5">{getFormattedPrice(pack.price)}</Box>
                                <Box className="fs25 fw500 ml5">/</Box>
                                <Box className="fs16 fw500">Per Person</Box>
                            </Box>
                            <Box sx={{ color: "#999", fontSize: "12px", mt: "5px" }}>*EXCLUDING APPLICABLE TAXES</Box>
                        </Box>
                        <Box display="flex" justifyContent="left" alignItems="center" sx={{ padding: "1rem", borderBottom: "1px solid #00000020" }}>
                            <ScheduleIcon color="error" fontSize="small" sx={{ mr: "5px" }} />
                            <Box sx={{ fontSize: "16px", fontWeight: "500" }}>{pack.duration}</Box>
                        </Box>
                        <Box display="flex" justifyContent="left" alignItems="center" flexDirection="column" sx={{ padding: "1rem" }}>
                            <Box className="fs20 fw600">Contact Us Now</Box>
                            <Box display="flex" alignItems="center" className="fs20 fw500 mt5">
                                <CallIcon color="error"  sx={{ mr: "5px" }} />
                                <Box>+91-9490247125</Box>
                                <Tooltip title="Travel India">
                                    <img src={whatsappicon} width="30" height="30" style={{ marginLeft: "5px" }}
                                        className="clickable"
                                        onClick={() => window.open(`https://api.whatsapp.com/send?phone=919490247125`, "_blank")}
                                    />
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="package-detail-footer">
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ fontWeight: "600", width:"40%", borderRight: "1px solid #dee2e6!important" }}>
                        <Box display="flex" alignItems="center">
                            <Box className="fs25 ml5">₹</Box>
                            <Box className="fs25 ml5">{getFormattedPrice(pack.price)}</Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Box className="fs25 fw500 ml5">/</Box>
                            <Box className="fs16 fw500">Per Person</Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ fontWeight: "600", width:"60%"}}>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Box className="fs20 fw600">Contact Us Now</Box>
                        </Box>
                        <Box display="flex" alignItems="center" className="fs16 fw500" justifyContent="center" sx={{mt:"10px"}}>
                            <CallIcon color="#fff"/>
                            <Box>+91-9490247125</Box>
                            <img src={whatsappicon1} width="30" height="30" style={{ marginLeft: "5px" }}
                                className="clickable"
                                onClick={() => window.open(`https://api.whatsapp.com/send?phone=919490247125`, "_blank")}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
export default Detail;