import React, { useEffect, useState } from "react";
import {
    ToggleButtonGroup, ToggleButton, Box
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import './AvailableDates.css';
const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUNE", "JULY", "AUG", "SEPT", "OCT", "NOV", "DEC"];
const AvailabileDates = (props) => {
    const [type, setType] = useState('list');
    const {dates} = props;
    const handleChange = (event, value) => {
        setType(value);
    };
    const color = "#c44242";
    const shouldDisableDate = (dateParam) =>{
        const y = dateParam.year();
        const m = dateParam.month() + 1;
        const d = dateParam.date();
        if (dates && dates.length > 0) {
            let year = dates.find(t => t.year == y);
            if (year) {
                let month = year.months.find(t => t.month == m);
                if (month) {
                    let date = month.dates.find(t => t == d);
                    if (date) {
                        return false;
                    }
                }
            }
        }
        return true;
    };
    const getAvailableDate = (y,m,d) => {
        return d + " " + months[parseInt(m) - 1] + " " + y;
    }
    return (
        <>
            <ToggleButtonGroup
                color="primary"
                value={type}
                exclusive
                onChange={handleChange}
                sx={{ width: "100%" }}
                aria-label="Platform"
            >
                <ToggleButton value="list" sx={{ width: "50%" }}>List</ToggleButton>
                <ToggleButton value="calender" sx={{ width: "50%" }}>Calender</ToggleButton>
            </ToggleButtonGroup>
            {
                type == "list" ?
                <ul className="availabledate-list">
                {
                    dates && dates.length > 0 && dates.map(y => (
                        <>
                            {
                                y.months.map(m => (
                                    <>
                                        {
                                            m.dates.map(d => (
                                                <li>
                                                    <Box className="availabledate">
                                                        <Box className="availabledate-text">
                                                            {getAvailableDate(y.year, m.month, d)}
                                                        </Box>
                                                        <Box className="availabledate-status">
                                                            (BOOKING OPEN)
                                                        </Box>
                                                    </Box>
                                                </li>
                                            ))
                                        }
                                    </>
                                ))
                            }
                        </>
                    ))
                }
            </ul>:
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar readOnly views={['day']} shouldDisableDate={(dateParam) => shouldDisableDate(dateParam)} />
                    </LocalizationProvider>
            }
            
        </>
    )
};
export default AvailabileDates;